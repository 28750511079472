<template>
<div>
  <b-card no-body class="overflow-hidden min-vh-100" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../assets/roc.png')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <b-card-text class="px-4 pt-4">
            <h2 class="pb-2">ROC&trade; Cylinder Tags</h2>
            The 'Ring of Confidence’ (ROC&trade;), cylinder tag is a coloured disc secured to a dive tank during the annual
            inspection of scuba dive cylinders. Air Purity is the exclusive provider of ROC&trade; tags. The colour of the ROC&trade; denotes the cylinder test year.
            <br>
            <br>
            <ul class="text-left">
                <li class="p-1">A visual test is conducted annually, with a hydrostatic test conducted bi-annually.</li>
                <li class="p-1">The colour of ROC&trade; denotes test year of the cylinder. Light brown: 2018, grey: 2019, dark green: 2020</li>
                <li class="p-1">A visible Class-2 denoting non-flammable compressed gas is a legal requirement.</li>
                <li class="p-1">The Diver Emergency number (0800 4 DES111) is embossed on all ROC&trade;. </li>
                <li class="p-1">ROC&trade; usage is recommended by the NZU, as per the "guide to gas cylinders" - Section 4.</li>
                <li class="p-1">The Ring Of Confidence - ROC&trade; - currently holds the New Zealand Trademark #662010.</li>
            </ul> 
          </b-card-text>

          <b-button v-b-modal.modal-2 variant="primary">Contact Us</b-button>

        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>

export default {
  name: 'ROCCylinderTags',
  data() {
      return {

      }
  },
  components: {
    
  }
}
</script>

<style>


</style>