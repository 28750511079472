<template>
<div>
  <div v-if="!complete">
    <h4 class="mb-2">Your Details:</h4>
    <b-form-input :state="validateCompany" class="my-2" v-model="company" placeholder="Company Name" type="text"></b-form-input>
    <b-form-invalid-feedback :state="validateCompany">
      You must enter a Company name.
    </b-form-invalid-feedback>

    <b-form-input :state="validateEmail" class="my-2" v-model="email" placeholder="Email Address" type="email"></b-form-input>
    <b-form-invalid-feedback :state="validateEmail">
      You must enter a valid email.
    </b-form-invalid-feedback>

    <b-form-input :state="validatePhone" class="my-2" v-model="phone" placeholder="Phone Number" type="tel"></b-form-input>
    <b-form-invalid-feedback :state="validatePhone">
      You must enter a valid phone number.
    </b-form-invalid-feedback>

    <h4 class="my-2">Quantity Required:</h4>
    <p class="my-1">Qty - bags (50 ROC&trade; per bag - includes records book):</p>
    <b-form-select v-model="selectedRoc" :options="optionsRocClass2"></b-form-select>
    <p class="my-1">Additional records books:</p>
    <b-form-select v-model="selectedRecordBooks" :options="optionsRocClass2"></b-form-select>
    <b-form-checkbox
        class="my-1"
        id="checkbox-1"
        v-model="enumber"
        name="checkbox-1"
        value="YES"
        unchecked-value=""
    >
      E-Numbering for Cylinder Boss? Check for 'Yes'
    </b-form-checkbox>

    <h4 class="my-2">Class 2 Stickers:</h4>
    <p class="my-1">Qty - sheets (25 stickers per sheet):</p>
    <b-form-select v-model="selectedClass2" :options="optionsRocClass2"></b-form-select>

    <h4 class="my-2">Fish ID Slates:</h4>
    <p class="my-1">Qty - packs of 10:</p>
    <b-form-select v-model="selectedFishId" :options="optionsRocClass2"></b-form-select>

    <p class="my-2">Comments and Queries:</p>
    <b-form-textarea v-model="comments" rows="3"></b-form-textarea>
    <div class="modal-footer">
      <button @click="closeModal" type="button" class="btn btn-secondary" >Close</button>
      <button @click="postToForms" type="button" class="btn btn-primary">Submit</button>
    </div>
  </div>

  <div v-if="complete" class="mx-auto w-50 p-5 flex">
    <p class="text-center">Thanks for your order!</p>
    <img class="mx-auto" src="../assets/greenTick.svg" alt="">
  </div>
</div>

</template>

<script>
import {LocalApi} from "../Api";

export default {
  name: 'OrderRoc',
  mounted() {
    this.api = new LocalApi();
  },
  data() {
      return {
          complete: null,
          validateCompany: null,
          validatePhone: null,
          validateEmail: null,
          company: "",
          email: "",
          phone: "",
          comments: "",
          selectedRoc: '',
          selectedRecordBooks: '',
          enumber: '',
          selectedClass2: '',
          selectedFishId: '',
          emailRegex: /^[\w!#$%&'+/=?`{|}~^-]+(?:\.[\w!#$%&'+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
          optionsRocClass2: [
          { value: '', text: '' },
          { value: 1, text: '1' },
          { value: 2, text: '2' },
          { value: 3, text: '3' },
          { value: 4, text: '4' },
          { value: 5, text: '5' },
          { value: 6, text: '6' },
          { value: 7, text: '7' },
          { value: 8, text: '8' },
          { value: 9, text: '9' },
          { value: 10, text: '10' },
          { value: 11, text: '11' },
          { value: 12, text: '12' }
          ]
      }
  },
  components: {
    
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-1');
    },
    async validateFields() {
      this.validateCompany = this.company.length > 0;
      this.validatePhone = this.phone.length > 0;
      this.validateEmail = this.emailRegex.test(this.email);
    },
    async postToForms() {
      await this.validateFields();

      if (this.validateEmail && this.validateCompany && this.validatePhone) {
        await this.api.postRocToForms(this.company, this.email, this.phone, this.selectedRoc, this.selectedRecordBooks,
         this.enumber, this.selectedClass2, this.selectedFishId, this.comments)
        this.complete = true;
      }
    }
  },
}
</script>

<style>


</style>