<template>
<div>
  <b-navbar fixed="top" sticy="true" toggleable="lg" type="light" variant="light">
    <b-navbar-brand href="#">
        
    </b-navbar-brand>
    <a href="#" @click="routeHome">
      <b-img left :src="require('@/assets/airpurity.svg')" alt="Airpurity" height="40px"></b-img>
    </a>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
          
        <!-- <img left :src="require('@/assets/airpurity.svg')" class="align-left" alt="Airpurity" height="auto"> -->
        <b-nav-item class="px-2" @click="routeHome" right>Home</b-nav-item>
        
        <b-nav-item class="px-2" @click="routeAbout" right>About</b-nav-item>

        <b-nav-item-dropdown class="px-2" text="Services and Training" right>
          <b-dropdown-item @click="routeAirPurityTest">Air Purity Testing</b-dropdown-item>
          <b-dropdown-item @click="routeAirFiller">Air Filler Compliance Certificates</b-dropdown-item>
          <b-dropdown-item @click="routeROC">ROC&trade; Cylinder Tags</b-dropdown-item>
          <b-dropdown-item @click="routeDAS">Dive Activity Supervisor</b-dropdown-item>
          <b-dropdown-item @click="routeMSCDB">Master Small Commercial Dive Boat</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-button v-b-modal.modal-1 class="px-2" variant="outline-success">Order ROC&trade;</b-button>

        <b-modal hide-footer scrollable id="modal-1" title="Order Form">
            <OrderRoc/>
        </b-modal>

        <b-modal hide-footer scrollable id="modal-2" title="Contact Us">
            <contact-form/>
        </b-modal>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import OrderRoc from "./OrderRoc.vue"
import ContactForm from "./ContactForm.vue"

export default {
  name: 'Navbar',
  created() {
      this.$root.$refs.Nav = this;
  },
  components: {
    OrderRoc,
    ContactForm
  },
  methods: {
      routeHome() {
          this.$router.push('/');
      },
      routeAbout() {
          this.$router.push('/about');
      },
      routeAirFiller() {
          this.$router.push('/airfillercert');
      },
      routeAirPurityTest() {
          this.$router.push('/airpuritytest')
      },
      routeDAS() {
          this.$router.push('/das')
      },
      routeMSCDB() {
          this.$router.push('/mscdb')
      },
      routeROC() {
          this.$router.push('/roc')
      },
  },
}
</script>

<style>


</style>