<template>
<div style="">
  <b-jumbotron fluid class="mx-auto my-auto w-75 transparentBG">
    <h1>About Air Purity Ltd</h1>
    <p class="py-4">Set up in 2013, Air Purity Limited is an independent, and 100% owned subsidiary of the New Zealand Underwater Association.
         In 1982, NZ Underwater identified the need to ensure safe diving practices were supported by quality assurance procedures extended to 
         dive cylinders and air filling equipment, thus developing the SCAP (Scuba Cylinder Audit Program). Today, Air Purity is a primary point 
         of contact for clean breathing air testing, SCAP cylinder audit and certification, air-fill operator certifications, and commercial boat
         training specific to the New Zealand underwater community.</p>
  </b-jumbotron>

   <b-card no-body class="overflow-hidden" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-body>
          <h2>Contact us</h2>
          <p class="px-4">Level 1, 40 Mt Eden Road, Auckland, 1024
          <br/>Tel: +64 9 623 3252
          <br/>Fax: +64 9 623 3253</p>
          <b-img fluid :src="require('../assets/apMap2.png')" class="rounded-lg"></b-img>
        </b-card-body>
      </b-col>
      <b-col md="6">
        <b-card-body>
            <h2>Leave us a message</h2>
            <contact-form/>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>
import ContactForm from '../components/ContactForm.vue'

export default {
  name: 'About',
  data() {
      return {

      }
  },
  components: {
    ContactForm
  }
}
</script>

<style>

</style>