<template>
<div>
  <b-card no-body class="overflow-hidden" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../assets/MSCDB.jpg')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <b-card-text class="px-4 pt-4">
            <h2 class="pb-2">Master Small Commercial Dive Boat</h2>
            <h6 class="p-2">A Maritime NZ approved dive industry certification issued on a 5-year period.</h6>
            This is industry-specific certificate is an approved training framework endorsed by Maritime NZ, falling under the Maritime Rule Part 35.70 and 35.72. 
            <br>
            <br>
                <strong> A current first-aid certificate must be held.</strong> 
            <br>
            <br>
            <ul class="text-left">
                <li class="p-1">This is a five year certification and requires annual membership renewal</li>
                <li class="p-1">Current first aid certification must be held</li>
                <li class="p-1">The Dive Activity Supervisor (DAS) course is included with this course</li>
                <li class="p-1">There are multiple MSCDB qualified trainers working throughout New Zealand </li>
            </ul>  
          </b-card-text>

          <b-button v-b-modal.modal-2 variant="primary">Contact Us</b-button>

        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>

export default {
  name: 'MasterSmallCommercial',
  data() {
      return {

      }
  },
  components: {
    
  },
  methods: {
      routeAirfill() {
          this.$root.$refs.Nav.routeAirfiller();
      }
  },
}
</script>

<style>


</style>