<template>
<div class="home" style="height: 100vh;">
  <b-jumbotron fluid class="mx-auto my-auto w-auto bg-transparent">
      <img src='../assets/airpurity.svg' class="w-75 transparentBG rounded-top px-4 pt-4 pb-4">
      <h6 class="mx-auto pb-4 w-75 pb-5 px-5 font-weight-light transparentBG rounded-bottom"><strong>Your primary point
        of contact for clean breathing air testing, air-filler compliance certification
        and SCAP cylinder audit and certification.</strong> </h6>
  </b-jumbotron>
</div>
</template>

<script>

export default {
  name: 'Home',
  data() {
      return {

      }
  },
  components: {
    
  }
}
</script>

<style>
.home {
    background-image: url("../assets/home.jpg");
    background-size: cover;
}

</style>