<template>
<div>
  <div v-if="!complete">
      <p class="mb-4">Your Details:</p>
      <b-form-input :state="validateName" required class="my-2" v-model="name" placeholder="Full Name" type="text"></b-form-input>
      <b-form-invalid-feedback :state="validateName">
        You must enter a name.
      </b-form-invalid-feedback>

      <b-form-input :state="validateEmail" required class="my-2" v-model="email" placeholder="Email Address" type="email"></b-form-input>
      <b-form-invalid-feedback :state="validateEmail">
        You must enter a valid email.
      </b-form-invalid-feedback>

      <b-form-input :state="validatePhone" required class="my-2" v-model="phone" placeholder="Contact Number" type="tel"></b-form-input>
      <b-form-invalid-feedback :state="validatePhone">
        You must enter a contact number.
      </b-form-invalid-feedback>

      <p class="my-4">Message:</p>
      <b-form-textarea :state="validateMessage" required v-model="message" rows="4"></b-form-textarea>
      <b-form-invalid-feedback :state="validateMessage">
        You must enter a message to send.
      </b-form-invalid-feedback>

      <div class="modal-footer">
        <button @click="postResponse" type="button" class="btn btn-primary">Submit</button>
      </div>
  </div>

  <div v-if="complete" class="mx-auto w-50 p-5 flex">
    <p>Thanks! We will be in touch.</p>
    <img class="mx-auto" src="../assets/greenTick.svg" alt="">
  </div>
</div>

</template>

<script>
import { LocalApi } from "../Api";

export default {
  name: 'ContactForm',
  mounted() {
    this.api = new LocalApi();
  },
  data() {
      return {
        complete: false,
        validateName: null,
        validateEmail: null,
        validateMessage: null,
        validatePhone: null,
        email: "",
        name: "",
        message: "",
        emailRegex: /^[\w!#$%&'+/=?`{|}~^-]+(?:\.[\w!#$%&'+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/

      }          
  },
  methods: {
    async validateFields() {
      this.validateName = this.name.length > 0;
      this.validateMessage = this.message.length > 0;
      this.validatePhone = this.phone.length > 0;
      this.validateEmail = this.emailRegex.test(this.email);
    },

    async postResponse() {
      await this.validateFields()
      if (this.validateName && this.validateEmail && this.validateMessage && this.validatePhone) {
        await this.api.postContactToForms(this.name, this.email, this.phone, this.message);
        this.complete = true;
      }

    },
  },
}
</script>

<style>


</style>