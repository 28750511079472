export class LocalApi {
    async postContactToForms(name, email, contact, message) {
        let formatMessage = message.replaceAll(" ", "+");
        let formatName = name.replaceAll(" ", "+");

        let formURL = 'https://docs.google.com/forms/d/e/1FAIpQLSe6_-VMuiMkx_oKS9vCMFrN5ByVgmmSe5fxBICwPyDZwEXRkg/formResponse?usp=pp_url&entry.1046464984='
            + formatName + '&entry.652849452=' + email + '&entry.59282561=' + contact + '&entry.282428292=' + formatMessage + '&submit=Submit';

        await fetch(formURL, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }

    async postRocToForms(company, email, number, roc, addRec, eNum, class2, fishID, comments) {
        let formatNumber = number.replaceAll(" ", "+");
        let formatCompany = company.replaceAll(" ", "+");
        let formatComments = comments.replaceAll(" ", "+");

        let formURL = 'https://docs.google.com/forms/d/e/1FAIpQLScxZrTY8U-afLUFZqp6EWKVX6DuKz0axz3SmYoK78qXBNgEcg/formResponse?usp=pp_url&entry.843836406='
            + formatCompany + '&entry.119189404=' + email + '&entry.1356915792=' + formatNumber + '&entry.1213158812=' + roc + '&entry.464323346=' + addRec
             + '&entry.1855004949=' + eNum + '&entry.1411743232=' + class2 + '&entry.271341352=' + fishID + '&entry.1792079416=' + formatComments + '&submit=Submit';

        await fetch(formURL, {
            method: 'GET',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }




}