<template>
  <div class="bg-white d-flex justify-content-between">
    <p class="pl-4 pt-3 text-center">© 2022, Air Purity Ltd.</p>
    <a href="https://www.sudo-code.dev">
      <b-img class="p-2 logoImg" src="@/assets/sudoLogo.png"></b-img>
    </a>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.logoImg {
  width: auto;
  height: 45px;
}
</style>