<template>
<div>
  <b-card no-body class="overflow-hidden" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../assets/airfiller.jpg')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <b-card-text class="px-4 pt-4">
            <h2 class="pb-2">Air Filler Compliance Certificates</h2>
            All cylinders with a water capacity of 500ml or more, filled in New Zealand,
            require the filler to hold a WorkSafe NZ compliance certificate in accordance
            with the Health and Safety at Work (Hazardous Substances) Regulations 2017.
            Failure to comply with this legislation could incur heavy penalties.
            <br>
            <br>
          </b-card-text>

          <b-button v-b-modal.modal-2 variant="primary">Contact Us</b-button>

        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>

export default {
  name: 'AirFillerCertificates',
  data() {
      return {

      }
  },
  components: {
    
  }
}
</script>

<style>

</style>