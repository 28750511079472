import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import './assets/styles.css'

import Home from '@/pages/Home'
import About from '@/pages/About'
import AirFillerCertificates from '@/pages/AirFillerCertificates'
import AirPurityTesting from '@/pages/AirPurityTesting'
import DiveActivitySupervisor from '@/pages/DiveActivitySupervisor'
import MasterSmallCommercial from '@/pages/MasterSmallCommercial'
import ROCCylinderTags from '@/pages/ROCCylinderTags'


Vue.config.productionTip = false
Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home},
  { path: '/about', component: About},
  { path: '/airfillercert', component: AirFillerCertificates},
  { path: '/airpuritytest', component: AirPurityTesting},
  { path: '/das', component: DiveActivitySupervisor},
  { path: '/mscdb', component: MasterSmallCommercial},
  { path: '/roc', component: ROCCylinderTags},

]

const router = new VueRouter({
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
