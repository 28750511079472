<template>
<div>
  <b-card no-body class="overflow-hidden" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../assets/airpuritytesting.jpg')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <b-card-text class="px-4 pt-4">
            <h2 class="pb-2">Air Purity Testing</h2>
            Air Purity testing mitigates risk to divers of breathing contaminated air and dive injury while ensuring compliance with the strict guidelines provided by WorkSafe NZ.
            In accordance with WorkSafe regulations for the guide to gas cylinders, it is a requirement for compressor operators to have their air tested on a 3-month period.
            Certification must be displayed at all times.
            <br>
            <br>
          </b-card-text>

          <b-button v-b-modal.modal-2 variant="primary">Contact Us</b-button>

        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>

export default {
  name: 'AirPurityTest',
  data() {
      return {

      }
  },
  components: {
    
  }
}
</script>

<style>

</style>