<template>
<div>
  <b-card no-body class="overflow-hidden" >
    <b-row no-gutters>
      <b-col md="6">
        <b-card-img :src="require('../assets/DAS.jpg')" alt="Image" class="rounded-0"></b-card-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <b-card-text class="px-4 pt-4">
            <h2 class="pb-2">Dive Activity Supervisor</h2>
            <h6 class="p-2">A 2-day course available to any diver or boat operator (crew) with a minimum age of 18 years. </h6>
            On completion participants receive a New Zealand Underwater Association certificate valid for five years with an annual renewal fee applicable.
             This course covers all aspects of dive control supervision and understanding any detrimental effects time and depth can have on a diver. 
             <br>
             <br>
             <strong> A current first-aid certificate must be held. </strong> 
            <br>
            <br>
            <ul class="text-left">
                <li class="p-1">Boat work such as anchoring, knots, changing weather patterns, chart reading, waves rips and tides, flags and floats. Pre dive diver safety checks and briefing</li>
                <li class="p-1">Pre dive diver safety checks and briefing</li>
                <li class="p-1">How to identify diving disorders including hypothermia, hyperthermia, hydrocution, Decompression Sickness</li>
                <li class="p-1">How to pick a safe dive site including checking for current, depth hazards and typography</li>
                <li class="p-1">How to monitor and record dive logs and all safety management including use of VHF and when to call for assistance</li>
                <li class="p-1">Basic diving rules</li>
            </ul> 
          </b-card-text>

          <b-button v-b-modal.modal-2 variant="primary">Contact Us</b-button>

        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</div>
</template>

<script>

export default {
  name: 'DiveActivitySupervisor',
  data() {
      return {

      }
  },
  components: {
    
  }
}
</script>

<style>


</style>